<template>
<section class="section">
  <div class="container">  
<div class="card">
<header class="card-header">
      <p class="card-header-title">
        {{pagetitle}}
      </p>
</header>
    <div class="card-content">
      <div class="content">
        <div class='list'>
          <ul>
            <div v-for="restaurant in restaurants" 
            :key="restaurant.id" 
            class="restaurant">  
          <div class='list-item'>
            <li> 
           <a :href="restaurant.weburl" target="_blank"  class="has-text-weight-bold" rel="noopener noreferrer" >
              {{restaurant.name}}
           </a>
          </li>
          </div> 
                
               </div>
               </ul>
             </div>      
          </div>
      </div>
      </div> 
      </div> 
           </section>
</template> 
<script> 
export default {
  data(){
  return {
  restaurants: [],
  pagetitle: "Patient-friendly Places to Eat"
  }
  },
    mounted(){
       // fetch(
    //   process.env.VUE_APP_API_URL +
    //     "/financialhelpers" +
    //     process.env.VUE_APP_API_URL_SUFFIX
    // )
        fetch('https://jsondataendpoint.s3.us-west-2.amazonaws.com/restaurants.json')
      .then(res => res.json())
      .then(data => this.restaurants = data)
     .catch(err => console.log(err.message))
},
}



</script>
 
