<template> 
  
<div class="columns">
  <div class="column">
  <FlightInfo></FlightInfo>
  <FinancialHelp></FinancialHelp>
  <AngelFlights></AngelFlights>
  </div>
  <div class="column">
  <ZipCodeForm></ZipCodeForm>
  </div>
  <div class="column">
  <ClinicList></ClinicList>
  <PlacesToStay></PlacesToStay>
  <PlacesToEat></PlacesToEat>
  </div> 
</div> 
<AppFooterVue></AppFooterVue>
</template>

<script>
 
import ZipCodeForm from "../components/ZipCodeForm.vue";
import ClinicList from "../components/ClinicListDisplay.vue";
import FinancialHelp from "../components/FinancialHelpDisplay.vue";
import FlightInfo from "../components/FlightsDisplay.vue";
import PlacesToStay from "../components/PlacesToStayDisplay.vue";
import PlacesToEat from "../components/PlaceToEatDisplay.vue";
import AngelFlights from "../components/AngelFlightsDisplay.vue";
 

export default {
  name: "App",
  components: {
    ZipCodeForm,
    ClinicList,
    FinancialHelp,
    FlightInfo,
    PlacesToStay,
    PlacesToEat,
    AngelFlights  
  },
};
</script> 