<template> 
 
   <div class="app">
  <NavBar></NavBar>
  <router-view/> 
  <AppFooter></AppFooter>
  </div> 
   
</template>

<style>  
  @import "https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.3/css/bulma.min.css";
  
 
</style>
<script>
import NavBar from "../src/components/NavBar.vue";
import AppFooter from "../src/components/AppFooter.vue";

export default {
  name: "App",
  components: { 
    NavBar,
    AppFooter
  },
};
</script>