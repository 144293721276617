<template>
<section class="section">
  <div class="container">   
<div class="card">
<header class="card-header">
      <p class="card-header-title">
      {{pagetitle}}  
      </p>
</header>
    <div class="card-content">
      <div class="content">
        <div class='list'>
          <ul>
            <div v-for="hotel in hotels" 
            :key="hotel.id" 
            class="hotel">  
          <div class='list-item'>
            <li> 
           <a :href="hotel.weburl" target="_blank"  class="has-text-weight-bold" rel="noopener noreferrer" >
              {{hotel.name}}
           </a>
          </li>
          </div> 
                
               </div>
               </ul>
             </div>      
          </div>
      </div>
      </div> 
      </div> 
      </section>  
</template> 
<script> 
export default {

  name: 'Hotels-View',
  data(){
  return {
  hotels: [],
  pagetitle: "Places to Stay"
  }
  },
    mounted(){
      
        fetch('http://jsondataendpoint.s3.us-west-2.amazonaws.com/hotels.json')
      .then(res => res.json())
      .then(data => this.hotels = data)
     .catch(err => console.log(err.message))
},
}



</script>
 