<template> 
  <div class="about">
    <h1>This is a Details page</h1>
  </div> 
</template>
<script> 


export default {
  name: "App",
  components: {   
  },
   
};


</script>