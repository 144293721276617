<template> 
<section class="section">
<div class="container">
    <h1 class="title">Blog</h1>
  <div class="about">
    <h1>This is the blog page</h1>
  </div>
  </div>
</section>
</template>
<script> 

export default {
  name: "App",
  components: {   
  },
};


</script>