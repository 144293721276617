import { createApp } from 'vue'

//import NavBar from "./components/NavBar.vue";
import App from './App.vue'
import router from './router'

createApp(App)
.use(router)
//.component("NavBar", NavBar)
.mount('#app')
