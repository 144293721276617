<template>   
<section class="section">

  <div class="container">
    <article class="panel is-primary">
  <p class="panel-heading">
   {{pagetitle}}
  </p>
  <p class="panel-tabs">
    <a class="is-active">Three closest NCI Clinics</a>
    <a>All</a>
  </p>
  <div class="panel-block">
    <p class="control has-icons-left">
      <input class="input is-primary" type="text" placeholder="Search">
      <span class="icon is-left">
        <i class="fas fa-search" aria-hidden="true"></i>
      </span>
    </p>
  </div>

   
   <div class='list'>
          <ul>
            <div v-for="clinic in clinics"  
            :key="clinic.id" 
            class="clinic">   
          <div class='list-item'>
          <li > 
          <a class="panel-block has-text-weight-bold" :href="clinic.weburl" target="_blank" rel="noopener noreferrer" >
          <span class="panel-icon">
          <i class="fas fa-book" aria-hidden="true"></i>
          </span> 
              {{clinic.name}}
           </a>
           <div> {{clinic.address1}} {{clinic.address1}}</div>
             <div> {{clinic.city}}, {{clinic.state}}, {{clinic.zipcode}}</div>
           <div>{{clinic.status}} </div>
           <span class="has-text-info">({{clinic.distance}} miles)</span>
          </li>
          </div> 
                
               </div>
               </ul>
             </div> 
                  
        
</article>

<!-- <div class="clinic-list-display">  
<div class="card">
<header class="card-header">
      <p class="card-header-title">
        {{pagetitle}}
      </p>
</header>
    <div class="card-content" >
      
      <div class="content">

        <div class='list'>
          <ul>
            <div v-for="clinic in clinics" 
            :key="clinic.id" 
            class="clinic">  
          <div class='list-item'>
               <li>
          <a :href="clinic.weburl" target="_blank"  class="has-text-weight-bold" rel="noopener noreferrer" >
              {{clinic.name}}
           </a>
           <div> {{clinic.address1}} {{clinic.address1}}</div>
             <div> {{clinic.city}}, {{clinic.state}}, {{clinic.zipcode}}</div>
           <div>{{clinic.status}} </div>
           <span class="has-text-info">({{clinic.distance}} miles)</span>
          </li>
          </div> 
                
               </div>
               </ul>
             </div>      
          </div>
      </div>
      </div> 
      </div>   -->
      </div>
      </section>
</template>
<script> 

export default {
  name: 'Clinic-View',
  data(){
  return {
  clinics: [],
  pagetitle: 'NCI Clinics',
  clinic: null
  }
  },
mounted() {
    // fetches the first clinic title just to make sure we are reaching the json file
    fetch(
      process.env.VUE_APP_API_URL +
        "/clinics/1" +
        process.env.VUE_APP_API_URL_SUFFIX
    )
      .then((res) => res.json())
      .then((data) => (this.clinic = data))
      .catch((err) => console.log(err.message));
    //should get the whole array of clinics
    // fetch(
    //   process.env.VUE_APP_API_URL +
    //     "/clinics" +
    //     process.env.VUE_APP_API_URL_SUFFIX
    // )
     fetch('https://jsondataendpoint.s3.us-west-2.amazonaws.com/clinics.json')
      .then((res) => res.json())
      .then((data) => (this.clinics = data))
      .catch((err) => console.log(err.message));
  },
};
</script>
<style>
/* left align the list */
div.card-content {
  text-align: center;
}
div.content ul {
  display: inline-flex;
  flex-direction: column;
}

div.content ul li {
  text-align: left;
}

</style>
 
