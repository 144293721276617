<template>     
    <div class="has-text-centered">
      <div class="notification">
        <div class="title">Let's get some help!</div>
        <form class="zipcode-form" @submit.prevent="onSubmit">
          <div class="field">
            <div class="control">
              <input
                id="zipcode"
                v-model="zipcode"
                class="input is-size-5"
                size="5"
                type="text"
                placeholder="Enter Your ZipCode"
                required
              />
            </div>
          </div>
          <button class="button is-primary is-large">Submit</button>
        </form>
     
      </div>
         <p></p>
        <p>Zipcode: {{ zipcode }}</p>
        <p>Top 3 Results...</p>
        <p><button class="button is-primary is-small">Fred Hutchinson, Seattle, WA</button> </p>
         <p><button class="button is-info is-small">Knight Cancer Institute, Portland, OR</button></p>
         <nbrsp></nbrsp>
         <p><button class="button is-info is-small">Huntsman Cancer Institute, Salt Lake City, UT</button></p>
           <router-link to="/" class="has-text-weight-bold" rel="noopener noreferrer" >Get more results... </router-link>  
    </div>  
      
</template>
<script>
export default {

  name: 'ZipCode-Search-View',
  data() {
    return {
    zipcode: '59801'
    }
  },
}
</script>

<style>
/* center the zipcode form */

 .zipcode-form
 {
 align-content: center;

}

</style>
      